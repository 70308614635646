.bg-footer,
.social-link,
.icon-style {
    color: var(--main-color-footer);
    background-color: var(--bg-color-footer);
    text-decoration: none;
}

.bg-footer a:hover {
    cursor: default;
}