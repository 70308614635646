.titre {
    text-align: center;
    color: #83CAC1;
}

.texte-justify {
    text-align: justify;
}

.img-center {
    text-align: center;
}

.bordure-basse {
    border-bottom: 2px solid #83CAC1;
}

.liste {
    list-style: none;
    text-align: justify;
}

.liste-choix {
    list-style: none;
    text-align: justify;
    padding-left: 0px;
}

.texte-center {
    text-align: center;
}

.bordure-cote {
    border-left: 2px solid #83CAC1;
    border-right: 2px solid #83CAC1;
}


.cursor a:hover {
    cursor: default;
}

.img-padding {
    padding-right: 0 !important;
    padding-left: 24px !important;
}


@media screen and (max-width:768px) {
    .liste {
        padding-left: 0;
    }
}