:root {
  --main-color-home: #83CAC1;
  --bg-color-home: #f8f9fa;

  --main-color-footer: #f8f9fa;
  --bg-color-footer: #83CAC1;
}

.site-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  font-family: 'Noto Sans', sans-serif;
  cursor: url('../assets/balayage.png'), auto;
}

a:hover {
  cursor: inherit;
}