.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-button.visible {
    opacity: 1;
}

.scroll-to-top-button.hidden {
    opacity: 0;
}