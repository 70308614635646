.titre-h3 {
    text-align: center;
    color: #83CAC1;
}

.btn-good,
.btn-good:active {
    color: #15161d !important;
    background-color: #83CAC1 !important;
    border-color: #83CAC1 !important;
}

.btn-good:hover {
    color: #fff !important;
    background-color: #83CAC1 !important;
    border-color: #83CAC1 !important;
}

.btn-good:focus,
.btn-good.focus {
    box-shadow: 0 0 0 0.2rem rgba(131, 202, 193, 0.5) !important;
}

/* Supprimer la mise en surbrillance (focus) des champs de saisie */
.form-control:focus {
    border-color: #83CAC1;
    box-shadow: 0 0 0 0.2rem rgba(131, 202, 193, 0.5) !important;
}

.custom-checkbox-label {
    display: flex;
    align-items: center;
}

.custom-checkbox {
    margin-right: 10px;
}

.custom-checkbox-label a {
    color: #83CAC1;
    text-decoration: none;
}

.custom-checkbox-label a:hover {
    text-decoration: underline;
}


.error-valide {
    color: red;
}