.custom-dropdown .nav-link,
.custom-dropdown .nav-link:hover,
.custom-dropdown .nav-link:active,
.custom-dropdown .dropdown-item {
    color: #83CAC1 !important;
}

/* Navbar.css */
.nav-link,
.nav-link:hover,
.nav-link:active {
    color: #83CAC1 !important;
}

.bg-navbar {
    background: var(--bg-color-home);
    font-size: x-large;
}

/* Ajoutez ces styles pour masquer la flèche du NavDropdown */
.custom-dropdown .dropdown-toggle::after {
    display: none;
}

.dropdown-item.active {
    background-color: var(--bg-color-home) !important;
}