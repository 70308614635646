.bg-img {
    background-image: url(../assets/home/img_home.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--main-color-home);
    height: 33.33vh;
    text-align: center;
}

.link-style {
    color: var(--main-color-home);
    text-decoration: none;
}

.border-basse {
    border-bottom: 2px solid var(--main-color-home);
}